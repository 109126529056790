
import React, { Suspense } from 'react';


import 'bootstrap/dist/css/bootstrap.min.css';

import { useSelector,useDispatch } from "react-redux";

import { useState,useEffect } from "react";
import axios from "axios";
import setProjects from './Redux/Actions/Actionprojects'
 import setCourses from './Redux/Actions/CourseAction'
 import { useNavigate  } from "react-router-dom";




//import Header from './components/Header';





import { BrowserRouter as Router, Routes,Route,Outlet  } from "react-router-dom";
const Header=React.lazy(()=> import('./components/Header'))
//const setProject=React.lazy(()=> import('./redux/Actions/Action'))
const Login=React.lazy(()=> import('./components/Login'))
const Securitycheck=React.lazy(()=> import('./components/Securitycheck'))
const Middle=React.lazy(()=> import('./components/Middle'))
const Projectrepo=React.lazy(()=> import('./components/Projectrepo'))
const Printfile=React.lazy(()=> import('./components/Printfile'))
const Defermenthistory=React.lazy(()=> import('./components/Defermenthistory'))
const StudentsInfo=React.lazy(()=> import('./components/StudentsInfo'))
const Viewmessage=React.lazy(()=> import('./components/Viewmessage'))
const Showpost=React.lazy(()=> import('./components/Showpost'))
const Subcomment=React.lazy(()=> import('./components/Subcomment'))
const Reloaddefr=React.lazy(()=> import('./components/Reloaddefr'))
const Myreducer=React.lazy(()=> import('./components/Myreducer'))
const Myreducerwobject=React.lazy(()=> import('./components/Myreducerwobject'))
const Request=React.lazy(()=> import('./components/Request'))

const Menu=React.lazy(()=> import('./components/Menu'))
const Home=React.lazy(()=> import('./components/Home'))
const Final=React.lazy(()=> import('./components/Final'))
const Applydeferment=React.lazy(()=> import('./components/Applydeferment'))
const Welcome=React.lazy(()=> import('./components/Welcome'))
const ES6toturial=React.lazy(()=> import('./components/ES6toturial'))
const Receptfile=React.lazy(()=> import('./components/Receptfile'))

function App() {

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  //  alert('Complete Cache Cleared')
  };


  
  clearCacheData()
  //  localStorage.clear();
 // alert("App")
     const ppi=localStorage.getItem("printf");
  //   localStorage.removeItem("printf")
     
     //  alert(ppi)
    // const userstate=useSelector((state)=> state.user)


     //localStorage.setItem("printf",0) 

    // console.log("print",ppi)
  const jabi=localStorage.getItem("regno");
 
  const [bigtosmall,setBigtosmall]=useState(0);     
  const [projetl,setProjetl]=useState([]);     
  const [registedc,setRegistedc]=useState([]);     

   useEffect(()=>{  


  //  const newpromise=new Promise((resolve,reject)=>{


      axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/reggistedcourses.php").then((result)=>{
        console.log("ram Niger new111:",result)
       // console.log("ram Niger:",process.env.REACT_APP_UNIVERSAL_URL)
             // setStatep(result.data)
             
             if(result.data.length>=1){
                //alert(12)
                
                 setRegistedc(result.data)
              // console.log("jakoli:::",result.data)
             }
          });

    //})



   axios.get(process.env.REACT_APP_UNIVERSAL_URL+"/dlcproject.php").then((result)=>{
   //console.log("Lokaji",result.data)
        if(result.data.length>=1){
            setBigtosmall(1)
          setProjetl(result.data)
         console.log("jakoli:::",result.data)
        } else{

            setBigtosmall(0)
        }
     });





   },[])
 
const dispatch=useDispatch();      
dispatch(setProjects(projetl))
//newpromise.then(()=>{
  //alert("Done")
//})

dispatch(setCourses(registedc))


//localStorage.setItem("printf",0)

//alert(ppi)


 // alert(jabi)

  return (
    <div className="App">
 {/*<Router><Header/></Router>*/}

    
   <Routes>

   {/* <Suspense fallback={<div>Loading...</div>} >
 <Header      dynama={ppi}/>
  </Suspense> */}

  <Route path="/welcome"  element={ <Suspense fallback={<div>Loading...</div>} ><Welcome /></Suspense>}  />
  <Route path="/myreducer"  element={ <Suspense fallback={<div>Loading...</div>} ><Myreducer /></Suspense>}  />
  <Route path="/myreducerwobject"  element={ <Suspense fallback={<div>Loading...</div>} ><Myreducerwobject /></Suspense>}  />










<Route path="/printfile"  element={
    <Suspense fallback={<div>Loading...</div>} >

<Printfile /> </Suspense>}  />
<Route path="/middle"  element={<Suspense fallback={<div>Loading...</div>} ><Middle /> </Suspense>}  />
<Route path="/home/"  element={  <Suspense fallback={<div>Loading...</div>} >
<Home /></Suspense>}  />
<Route path="/applydeferment"  element={  <Suspense fallback={<div>Loading...</div>} >
<Applydeferment /> </Suspense>} exact  />
<Route path="/projectrepo"  element={ <Suspense fallback={<div>Loading...</div>} ><Projectrepo /> </Suspense>}  />

<Route path="/dhistory"  element={ <Suspense fallback={<div>Loading...</div>} ><Defermenthistory /> </Suspense>}  />
<Route path="/studentsInfo/:regno/:transatid/:sessionid"  element={ <Suspense fallback={<div>Loading...</div>} ><StudentsInfo /> </Suspense>}  />
<Route path="/viewmessage/:regno/:transatid"  element={ <Suspense fallback={<div>Loading...</div>} ><Viewmessage /> </Suspense>}  />

<Route path="/es6"  element={ <Suspense fallback={<div>Loading...</div>} ><ES6toturial /></Suspense>}  />
<Route path="/reloaddefr"  element={ <Suspense fallback={<div>Loading...</div>} ><Reloaddefr /></Suspense>}  />




<Route path="/Request/:id"  element={
 <Suspense fallback={<div>Loading...</div>} >

<Request />  </Suspense>
}  />





<Route path="/Securitycheck/:regno"  element={
 <Suspense fallback={<div>Loading...</div>} >

<Securitycheck />  </Suspense>
}  />



<Route path="/receptfile/:regno"  element={
 <Suspense fallback={<div>Loading...</div>} >

<Receptfile />  </Suspense>
}  />



<Route path="/final"  element={

 <Suspense fallback={<div>Loading...</div>} >
 <Final />
</Suspense>



}  />




<Route path="/showpost/:sregno/:postid" 
 element={ <Suspense fallback={<div>Loading...</div>} ><Showpost /> </Suspense>}  />

{/* <Route path="/Securitycheck/:regno"  element={
<Suspense fallback={<div>Loading...</div>} >
<Securitycheck />   </Suspense> }   />
*/}
<Route path="/es6"  element={ <Suspense fallback={<div>Loading...</div>} ><ES6toturial /></Suspense>}  />






<Route path="/"  element={  <Suspense fallback={<div>Loading...</div>} >   <Login />   </Suspense>}  />
<Route path="/subcomment"  element={  <Suspense fallback={<div>Loading...</div>} >   <Subcomment />   </Suspense>}  />




{/* <Route path="/"  element={<Test />}  /> */}
<Route>404 Not Found!</Route>




<Route>404 Not Found!</Route>

</Routes>  
    </div>
  );

  



}

export default App;
