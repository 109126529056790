const container=[{initial:"empty courses"}]
   const CourseReducer=(state=container,action)=>{

   // console.log("payload 2",action.payload)
     switch(action.type){

    case'COURSES':
    return {...state,courses:action.payload}

    default:
      return state;

     }
   }


   export default CourseReducer;