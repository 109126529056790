const container=[{initial:"empty Projects"}]
   const ProjectReducer=(state=container,action)=>{

   // console.log("payload 2",action.payload)
     switch(action.type){

    case'PROJECTS':
    return {...state,projects:action.payload}

    default:
      return state;

     }
   }


   export default ProjectReducer;