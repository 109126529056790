import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import { UserProvider } from './components/ContextAPI2/ContextAPI2';

import { Provider } from 'react-redux';// is responsible of linking our reduce store with all the components in our appllication 




  import store from './Redux/Store/Store'

  // console.log("Current state:", store.getState())
  // const clearCacheData = () => {
  //   caches.keys().then((names) => {
  //     names.forEach((name) => {
  //     //  caches.delete(name);
  //     });
  //   });
  // //  alert('Complete Cache Cleared')
  // };


  
 // clearCacheData()


ReactDOM.render(
  <React.StrictMode>

  <UserProvider>
   <BrowserRouter>
  <Provider store={store}>

  <App />

  </Provider>
   
  </BrowserRouter>
  </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

