
 import CourseReducer from './CourseReducer'
 import ProjectReducer from './ProjectReducer'
import { combineReducers } from 'redux'


 const allReducers=combineReducers({
    courses:CourseReducer,
    Project:ProjectReducer
  
})


export default allReducers;  