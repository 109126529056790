// ContextAPI.jsx
import React, { createContext, useEffect, useState,useRef  } from "react";

import axios from "axios";


export const ContextAPI2 = createContext({});

export const UserProvider = ({ children }) => {
 
    const [showpayment,setShowpayment]=useState(0)
 
  

  
    return (
        <ContextAPI2.Provider value={{
showpayment,setShowpayment

        }}>
            {children}
        </ContextAPI2.Provider>
    );
};
