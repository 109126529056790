function setCourses(cos){
    //  console.log(episode)
   return {
   
       type:'COURSES',
       payload:cos,
   }
   
   }  
   
   
   
   export default setCourses;
   
   
   